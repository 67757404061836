import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import FormEditCourseStudents from '@uz/unitz-tool-components/FormEditCourseStudents';
import _ from 'lodash';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="isLoading">
          <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
            <LoadingScreen />
          </div>
        </DIV>
        <DIV className="notLoading">
          <div className="p-4 h-full">
            <div className="mb-4 text-main uppercase font-semibold text-base">
              {ctx.apply('i18n.t', 'ZoomToolStudent.edit_students_title')}: {ctx.get('@item.name')}
            </div>
            <div className="p-4 bg-white500 rounded-lg shadow-xl">
              <FormEditCourseStudents />
            </div>
          </div>
        </DIV>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
