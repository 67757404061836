const _ = require('lodash');

const isEmpty = (value) => _.isUndefined(value) || _.isNull(value) || value === '';

const ox = (value, tempStr, def = '') => {
  if (_.isPlainObject(value)) {
    const rtn = [];
    _.map(value, (val, key) => {
      const keyVal = `${ox(val, tempStr, def)}`;
      if (keyVal.length) {
        rtn.push(`${key}: ${ox(val, tempStr, def)}`);
      }
    });
    return rtn.join(', ');
  }
  if (isEmpty(value)) return def;
  return _.template(tempStr)({ value });
};
exports.ox = ox;

const cx = (item, tpl) => {
  const rtn = [];
  const applyTpl = (value) => {
    if (_.isString(tpl)) {
      return _.template(tpl)({ value });
    } if (_.isFunction(tpl)) {
      return tpl(value);
    }
    return value;
  };

  if (_.isPlainObject(item)) {
    _.map(item, (val, key) => {
      if (!isEmpty(val)) {
        rtn.push(applyTpl(key));
      }
    });
  } else if (_.isString(item)) {
    rtn.push(applyTpl(item));
  } else if (_.isArray(item)) {
    rtn.push(...item.map((val) => cx(val, tpl)));
  }

  return rtn.join(', ');
};

exports.cx = cx;

const getUpdateColumns = (payload) => {
  const keys = [];
  if (_.isArray(payload) && payload.length) {
    return getUpdateColumns(payload[0]);
  }
  _.map(payload, (val, key) => {
    if (_.isPlainObject(val) && val.data) {
      return;
    }
    keys.push(key);
  });
  return keys;
};

exports.getUpdateColumns = getUpdateColumns;
