import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import gstyles from '@vl/gstyles';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { flattenGet } from '@vl/mod-utils/flattenGet';
import { getUpdateColumns } from '@vl/mod-utils/gqlhelpers';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            stepForm_2: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const route = useRoute();
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  multiple_quantity: 0,
                  session_duration: ctx.get('@item.session_duration'),
                  start_at: ctx.get('@item.start_at'),
                  type: ctx.get('@item.type'),
                  lessons: ctx.get('@item.course_rooms'),
                  students: _.map(_.uniqBy(ctx.get('@item.course_room_attendees'), 'member.user_id'), (student) => {
                    return {
                      ..._.omit(student, ['member']),
                      id: _.get(student, 'member.id', ''),
                      user_id: _.get(student, 'member.user_id', ''),
                      member_profile: _.get(student, 'member.member_profile', {}),
                    };
                  }),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const formSteps = ctx.get('formSteps');
                    const course_id = ctx.get('@item.id');
                    const client = await getClient();

                    const course_payload = _.omit(
                      {
                        id: course_id,
                        // account_id,
                        user_id,
                        name: ctx.get('@item.name', ''),
                        description: ctx.get('@item.description', ''),
                        start_at: ctx.get('@item.start_at', ''),
                        type: ctx.get('@item.type', ''),
                        session_duration: ctx.get('@item.session_duration', ''),
                      },
                      ['account_id']
                    );

                    const room_payload = _.map(_.get(formSteps[0], 'form.values.lessons', []), (lesson) => {
                      const start_at = _.get(lesson, 'start_at', '');
                      const duration = ctx.get('@item.session_duration', '');
                      let course_room_students_payload = {};
                      const students = [..._.get(formSteps[0], 'form.values.students', [])];
                      if (students.length) {
                        let student_payload_one = {};
                        course_room_students_payload = {
                          course_room_attendees: {
                            data: _.map(students, (student_payload) => {
                              student_payload_one = _.pick(student_payload, ['user_id']);
                              return {
                                ...student_payload_one,
                                account_id,
                                course_id,
                              };
                            }),
                            on_conflict: {
                              constraint: 'course_room_attendee_user_id_course_room_id_account_id_key',
                              update_columns: ['user_id'],
                            },
                          },
                        };
                      }

                      return _.omit(
                        {
                          account_id,
                          course_id,
                          start_at,
                          end_at: moment(start_at)
                            .clone()
                            .add(duration, 'second')
                            .format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                          ..._.pick(lesson, ['id']),
                          name: _.get(lesson, 'name', ''),
                          description: _.get(lesson, 'description', ''),
                          duration,
                          ...course_room_students_payload,
                        },
                        ['course_id']
                      );
                    });
                    const payload = {
                      delete_course_room_attendee_exp: {
                        _or: _.map(room_payload, (room) => {
                          return {
                            course_id: { _eq: course_id },
                            course_room_id: { _eq: room.id },
                            user_id: { _nin: flattenGet(room, 'course_room_attendees.data.user_id') },
                          };
                        }),
                      },
                      object: {
                        ...course_payload,
                        course_rooms: {
                          data: room_payload,
                          on_conflict: {
                            constraint: 'course_room_pkey',
                            update_columns: getUpdateColumns(room_payload),
                          },
                        },
                      },
                      on_conflict: {
                        constraint: 'course_pkey',
                        update_columns: getUpdateColumns(course_payload),
                      },
                    };

                    console.log('payload', payload);

                    const course_res = await client.request(
                      gql`
                        mutation EditCourse(
                          $object: b2b_course_insert_input!
                          $on_conflict: b2b_course_on_conflict!
                          $delete_course_room_attendee_exp: b2b_course_room_attendee_bool_exp!
                        ) {
                          delete_b2b_course_room_attendee(where: $delete_course_room_attendee_exp) {
                            affected_rows
                          }
                          course: insert_b2b_course_one(object: $object, on_conflict: $on_conflict) {
                            id
                            course_rooms {
                              id
                              course_room_attendees {
                                id
                                user_id
                              }
                            }
                          }
                        }
                      `,
                      payload
                    );

                    if (course_res) {
                      route.navigate(ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', { id: course_id }));
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.isValid,
                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                array: {
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });
              return form;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            {/* <div className="text-xs"> Type: {_.get(val, 'role', '')}</div> */}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_1.array.remove', 'teachers', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourseTeacher'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('stepForm_1.values.teachers');
              const tableName = ctx.get('tableName', 'tblCourseTeacher');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),

            allStudentColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.student')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            {/* <div className="text-xs"> Type: {_.get(val, 'role', '')}</div> */}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_2.array.remove', 'students', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableStudentName: hook(() => 'tblCourseStudent'),

            tableStudentColumn: hook((ctx) => {
              const data = ctx.get('stepForm_2.values.students');
              const tableName = ctx.get('tableName', 'tblCourseStudent');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allStudentColumns');

              const columns = _.filter(ctx.get('allStudentColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
            formSteps: hook((ctx) => {
              return [
                {
                  form: ctx.get('stepForm_2'),
                },
              ];
            }),

            formStepsModel: hook((ctx) => {
              const [currentStep, $currentStep] = React.useState(0);
              const formSteps = ctx.get('formSteps');
              const stepCount = formSteps.length;
              const ref = React.useRef({});
              _.assign(ref.current, { currentStep, $currentStep });
              const model = {
                current: ref.current.currentStep,
                isStep: (index) => index === ref.current.currentStep,
                next: () => {
                  ref.current.$currentStep(Math.min(ref.current.currentStep + 1, stepCount - 1));
                },
                prev: () => {
                  ref.current.$currentStep(Math.max(ref.current.currentStep - 1, 0));
                },
                isLast: () => {
                  return ref.current.currentStep === stepCount - 1;
                },
                isFirst: () => {
                  return ref.current.currentStep === 0;
                },
                hasNext: () => {
                  return stepCount > 1 && !model.isLast();
                },
                hasPrev: () => {
                  return stepCount > 1 && !model.isFirst();
                },
                getForm: () => {
                  return ctx.get(`formSteps.${ref.current.currentStep}.form`);
                },
              };
              return model;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
